/* Import Regular Font */
@font-face {
  font-family: 'NType82';
  src: url('../../font/PTMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Import Bold Font */
@font-face {
  font-family: 'NType82-Bold';
  src: url('../../font/PT Mono Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Container Styles */
.about__container {
  font-family: 'NType82', sans-serif; /* Apply regular font */
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 4rem;
  justify-content: center; /* Center the container */
}

/* Image Styles */
.about__img {
  width: 350px;
  border-radius: 1.5rem;
  display: block; /* Ensure image is displayed as a block element */
  margin: 0 auto; /* Center image horizontally */
}

/* Info Box Styles */
.about__info {
  grid-template-columns: repeat(3, 140px);
  gap: 0.5rem;
  margin-bottom: var(--mb-2);
}

/* Box Styles */
.about__box {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.12); /* Fixed opacity value */
  border-radius: 0.75rem;
  text-align: center;
  padding: 1rem 1.25rem;
}

/* Icon Styles */
.about__icon {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-5);
}

/* Title Styles */
.about__title {
  font-family: 'NType82-Bold', sans-serif; /* Apply bold font */
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

/* Subtitle Styles */
.about__subtitle {
  margin-top: 1%;
  font-family: 'NType82', sans-serif; /* Apply regular font */
  font-size: var(--smaller-font-size);
}

/* Description Styles */
.about__description {
  font-family: 'NType82', sans-serif; /* Apply regular font */
  padding: 0 4rem 0 0;
  margin-bottom: var(--mb-2-5);
}

/* Mobile Styles */
/* Mobile Styles */
@media screen and (max-width: 992px) {
  .about__container {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
    margin: 0 auto; /* Center the container */
    padding: 0 1rem; /* Add padding to avoid content touching edges */
  }

  .about__img {
    width: 220px;
    margin: 0 auto; /* Center image horizontally */
  }

  .about__box {
    padding: 0.75rem 0.5rem;
    align-items: center;
    flex: 1; /* Allow boxes to grow and shrink equally */
    min-width: 0; /* Prevent boxes from being too narrow */
  }

  .about__data {
    text-align: center;
  }

  .about__info {
    display: flex; /* Use flexbox for horizontal alignment */
    flex-direction: row; /* Ensure boxes are in a single row */
    justify-content: space-between; /* Distribute space evenly */
    gap: 1rem; /* Add space between boxes */
    flex-wrap: nowrap; /* Prevent wrapping of boxes */
  }

  .about__description {
    padding: 0;
    margin-bottom: 2rem;
    text-align: center; /* Center description text */
  }
}

@media screen and (max-width: 576px) {
  .about__info {
    flex-direction: row; /* Ensure boxes are in a single row */
    gap: 0.5rem; /* Adjust space between boxes */
  }
}

@media screen and (max-width: 350px) {
  .about__info {
    flex-direction: row; /* Ensure boxes are in a single row */
    gap: 0.5rem; /* Adjust space between boxes */
  }
}

/* Section Subtitle Styles */
@media only screen and (max-width: 600px) {
  .section__subtitle {
    margin-top: 5%;
    text-align: justify;
    margin-left: 10%; /* Adjust margin for better alignment */
    margin-right: 10%; /* Adjust margin for better alignment */
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .section__subtitle {
    text-align: justify;
    margin-left: 10%; /* Adjust margin for better alignment */
    margin-right: 10%; /* Adjust margin for better alignment */
  }
}

@media only screen and (min-width: 1025px) {
  .section__subtitle {
    text-align: justify;
    margin-top: 1%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
