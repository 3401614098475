/* LoadingScreen.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black; /* or a dark grey, depending on preference */
  position: relative;
}

.circle {
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #d3d3d3; /* Light grey */
  border-right-color: #d3d3d3; 
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.circle1 {
  width: 80px;
  height: 80px;
  animation: spin1 1.5s linear infinite;
}

.circle2 {
  width: 60px;
  height: 60px;
  border-top-color: #a9a9a9; /* Dark grey */
  border-right-color: #a9a9a9; 
  animation: spin2 1s linear infinite;
}

.circle3 {
  width: 40px;
  height: 40px;
  border-top-color: #696969; /* Dim grey */
  border-right-color: #696969;
  animation: spin3 0.75s linear infinite;
}

@keyframes spin1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes spin3 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Media query for all screen types */
/* Apply 100% width to elements with the class 'loading-container' */
/* Apply 100% width to elements with the class 'loading-container' */
.loading-container {
  width: 100% !important;
}
